.d-password{
  display: grid;
  grid-template-columns: auto 2em;
}

.d-password .column-icon {
  display: flex;
  justify-content: right;
  align-items: center;
  color: var(--dark-theme-text);
}