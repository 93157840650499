.body-registration {
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
    background-color: #083924;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
 }

 .back-button {
   position: absolute;
   top: 0;
   left: 0;
}

 .body-registration input {
    text-align: center;
 }
 
 
 .sponsorClass {
    background: #ccc !important;
 }
 
 
 .sponsorDatos {
    background-color: transparent !important;
 }
 
 .container-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
 }

 .container-register {
    /* height: 65%; */
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
 }

 .p-start {
    font-size: 15px;
 }
 #logo_desktop {
    max-width: 100%;
 }

 #logo_desktop img {
    width: 60%;
    margin-left: 100px;
 }
 .texto {
    background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
 }

 .button-register button a {
    color: inherit;
    text-decoration: none;
 }

 .button-register button {
    width: 170px;
    background-color: #083924;
    color: #f0f0f0;
    padding: 10px 20px;
    border: 2px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none !important;
    transition: background-color 0.2s, border 0.2s; /* Agrega transición suave */
 }
 .button-register button:hover {
    background-color: #0d4a30;
    border: 2px solid #083924; /* Restablece el borde en estado hover */
 }

 /* boton de registro /registration */
 .button-register-registration a {
    color: inherit;
    text-decoration: none;
 }

 .button-register-registration {
    width: 170px;
    background-color: #083924;
    color: #f0f0f0;
    padding: 10px 20px;
    border: 2px solid #083924;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none !important;
 }
 .button-register-registration:hover {
    background-color: #0d4a30;
    border-color: #083924;
 }
 .centered-content-correo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 }
 
 .button-registerData {
    text-align: center;
 }
 
 
 .centered-content-correo button {
    vertical-align: middle;
 }
 
 .centered-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 40vh; */
 }
 
 .centered-content button {
    justify-content: center;
 }
 

 .form-group {
    width: 300px;
    margin: auto;
 }
 .form-group p {
    margin-top: 10px;
    font-size: 12px;
 }
 .form-group input {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
 }
 /* .form-group input:first-child {
    margin-bottom: 20px;
 } */
 .form-group input::placeholder {
    text-align: center;
 }
 /* user_validation */
 
 .texto-datosCuenta {
    background-color: #f0f0f0;
    padding: 20px;
    /* height: 40vh; */
    text-align: center;
 }
 
 .texto-datosCuenta > p {
    margin-bottom: 80px;
 }

 .centered-content-datosCuenta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 40vh; */
 }
 
 .form-group-datosCuenta {
    width: 300px;
 }
 
 .form-group-datosCuenta p {
    margin-top: -10px;
    font-size: 12px;
 }

 .form-group-datosCuenta input {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
 }
 
 .form-group-datosCuenta input:first-child {
    margin-bottom: 20px;
 }
 
 
 .form-group-datosCuenta input::placeholder {
    text-align: center;
 }
 

 .form-group-tyc input[type="checkbox"] {
    text-align: left !important;
    margin-right: 10px;
 }

 .form-group-tyc label {
    font-size: 12px;
 } 
 
 .form-group-tyc a {
    text-decoration: none;
    color: var(--primary-color-black) !important;
 }

 .form-group-tyc {
    text-align: left;
    bottom: 75px;
 }

 .form-group-tyc label {
    align-items: center;
    font-size: 12px;
    margin-bottom: 35px;
    white-space: nowrap;
 }
  .form-group-tyc input[type="checkbox"] {
    margin-right: 4px;
 }

 /* user_validation/:sponsor */
 .centered-content-sponsor {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 40vh; */
 }

 .form-group-tyc-sponsor {
    position:absolute;
    text-align: left;
    bottom: 100px;
 }

 .form-group-tyc-sponsor label {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 35px;
    white-space: nowrap;
 }
  .form-group-tyc-sponsor input[type="checkbox"] {
    margin-right: 4px;
 }

 .form-group-sponsor {
    width: 300px;
    margin: auto;
 }

 .form-group-sponsor p {
    margin-top: -10px;
    font-size: 12px;
 }

 .form-group-sponsor input {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
 }
 
 
 .form-group-sponsor input:first-child {
    margin-bottom: 20px;
 }
 
 
 .paso {
    margin-top: 0;
 }
 
 
 /* Componente validar correo */
 
 .input-validate {
    display: flex;
    flex-direction: row;
    justify-content: center;
 }

 /* .form-group.validate {
    margin-left: 15px;
 } */
 .input-validate input {
    /* width: 60px;
    height: 40px; */
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 20px;
    margin: 0 10px;
 }

 .hide-number-input-arrows::-webkit-inner-spin-button,
 .hide-number-input-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
 }
 
 .hide-number-input-arrows {
    appearance: none;
  -moz-appearance: textfield;
 }
 .form-group.validate p {
    text-align: center;
    margin-top: 10px;
 }

 /* password */
 .form-group-password {
    width: 300px;
 }
 .form-group-password input {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
 }
 
 
 .form-group-password input:first-child {
    margin-bottom: 20px;
 }
 
 
 /* Phone */
 
 .form-group-phone {
    width: 300px;
    margin-bottom: 30px;
 }
 .form-group-phone input {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 0 10px 10px 0;
 }

 .centered-text {
    margin-top: 10px;
    font-size: 12px;
 }

 .select-country select {
    border-right: none !important;
    color: gray !important;
    background-color: rgb(253, 253, 253) !important;
    display: block !important;
    padding-right: 20px !important;
    border: 1px solid #ccc !important;
    border-radius: 10px 0 0 10px;
    text-align: left !important;
    width: 40% !important;
    text-decoration: none;
 }

 .select-country {
    flex: 1;
    display: flex;
    width: 100%;
    height: 40px;
 }

 /* Datos personales */
 .form-group-datos {
    position: relative;
    width: 300px;
    margin: auto;
 }

 .form-group-datos input {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 10px;
 }

 .form-group-datos select {
    color: rgb(123, 121, 121);
    background: #ffffff88;
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
 }

 
 
 .form-group-datos button {
    margin-top: 10px;
 }
 
 
 .form-group-datos input:first-child {
    margin-bottom: 10px;
 }
 
 
 /* componente email */
 .button-registerData button a {
    color: inherit;
    text-decoration: none;
 }

 .button-registerData button {
    background-color: #083924;
    color: #f0f0f0;
    padding: 10px 20px;
    border: 2px solid #083924;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none !important;
 }

 .button-registerData button:hover {
    background-color: #0d4a30;
    border-color: #083924;
 }

 @media (max-width: 1000px) {
    /* .container-register {
        display: none;
    } */
   
    .logo {
        margin-left: -20px;
    }
    .texto{
        width: 100%;
    }
    p {
        font-size: 12px;
    }
    .texto-datosCuenta{
        width: 100%;
    }
    .container-register {
        width: 90%;
    }
    .form-group {
        width: 100%;
    }
    .form-group-datos {
        width: 100%;
    }
    .form-group-datosCuenta {
        width: 100%;
    }
    .centered-content-datosCuenta {
        width: 100%;
    }
    .form-group-tyc {
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        text-align: center;
        bottom: 0;
    }

    .form-group-tyc label {
        display: block;
        font-size: 10px;
        max-height: none;
        overflow: visible;
    }

 
 
    .input-validate{
        width: 100%;
    }
 

    .form-group-phone {
        width: 100%;
    }
 }
