.body-recovery-password {
  margin: 0;
  padding: 0;
  overflow: hidden;
  /* Esto evitará el desbordamiento vertical */
  background-color: #083924;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  /* Para alinear los elementos verticalmente */
}

.body-recovery-password input {
  text-align: center;
}


.container-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;
}


.container-login-recoveryPassword {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  min-width: 40%;
  min-height: 60%;
  border-radius: 10px;
}


.p-start {
  font-size: 10px;
}


#logo_desktop {
  max-width: 100%;
  height: auto;
}


#logo_desktop img {
  width: 60%;
  margin-left: 100px;
}


.texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  padding: 20px;
  margin-top: 50px;
  text-align: center;
}


.button-login button a {
  color: inherit;
  text-decoration: none;
}


.button-login button {
  width: 170px;
  background-color: #083924;
  color: #f0f0f0;
  padding: 10px 20px;
  border: 2px solid #083924;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none !important;
}


.button-login button:hover {
  background-color: #0d4a30;
  border-color: #083924;
}


.centered-content {
  margin-top: 100px;
  text-align: center;
}


.form-group-recoveryPassword {
  width: 300px;
  margin-bottom: 30px;
}


.form-group-recoveryPassword p {
  font-size: 10px;
  margin: 0 auto;
  margin-top: 15px;
}


.form-group-recoveryPassword input {
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}


#input-data {
  text-align: center;
  margin-bottom: 3px;
}


.form-group-recoveryPassword input::placeholder {
  text-align: center;
}


@media (min-width: 370px) and (max-width: 400px) {
  #logo_desktop img {
    margin: 0 auto;
    align-items: center;
    width: 80%;
  }

  .texto {
    font-size: 15px;
  }

  .p-start {
    font-size: 20px;
  }
}


@media (max-width: 1000px) {
  #logo_desktop {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    width: 80%;
  }

  .texto {
    font-size: 15px;
  }

  .p-start {
    font-size: 20px;
  }
}