.recomiendate-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
}

.recomiendate-section {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.recomiendate-section h2 {
    font-size: 25px;
    font-weight: 600;
    text-decoration: underline;
}

.recomiendate-section h3 {
    margin-top: 1rem;
}

.recomiendate-section p {
    font-size: 14px;
    font-weight: 500;
}

.recomiendate-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
}

.recomiendate-input label {
    border-radius: 15px;
    margin-bottom: 15px;
    background: #D9D9D9;
    padding: 5px;
}

.recomiendate-input input {
    border: none;
    border-radius: 15px;
    text-align: center;
    box-shadow: 1px 1px 1px rgb(184, 182, 182);
}

.recomiendate-button {
    padding: 5px 10px 5px 10px;
    border-radius: 18px;
    margin-top: 10px;
    border: none;
    background-color: #226021;
    color: #fff;
}

.recomiendate-button:hover {
    background-color: #2b9529;
}

.recomiendate-button:active {
    transform: scale(0.95);
}