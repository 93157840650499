.nav-layout {
  /* background-color: var(--background-trasnparent); */
  height: 100%;
  user-select: none; 
}

@media screen and (max-width: 960px) {
  .nav-link {
    min-height: auto !important;
  }

  .container-dashboard {
    max-width: 100% !important;
  }

  .nav-layout {
  border-bottom: solid 1px var(--border);
  }
  .nav-layout > ul {
    display: none;
  }
  .nav-layout > ul.show-nav {
    display: block;
  }

  .nav-link:not(.show-nav) {
    display: none;
  }

  .logos-nav {
    display: none !important;
  }

  .container-layout {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 960px) {
  .nav-menu {
    display: none;
  }
  .nav-layout {
    border-right: solid 1px var(--border);
  }
  .container-layout:not(.minimize) {
    display: grid;
    grid-template-columns: 210px auto;
  }

  .container-layout.minimize {
    display: grid;
    grid-template-columns: 50px auto;
  }

  .container-layout.minimize .nav-link span:not(.btn) {
    display: none;
  }
}

.nav-layout li .menu-icon {
  /* padding: 0.25em 0.4em; */
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0.8em;
  left: 0.5em;
  /* display:inline; */
  width: 2em;
  height: 2em;
  align-items: center;
  border-radius: .5em;

  /* background: #645df5;
  color: #ffffffdd; */
}

a  {
  text-decoration: none !important;
}


.nav-link {
  font-size: 20px !important;
  list-style: none;
  min-height: 100vh;
  padding: 0 !important;
}

.nav-link li:not(.sub-li) a {
  padding: 1em 1em;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  gap: 1em;
  justify-content: left;
  align-items: center;
}

.nav-link li:not(.sub-li) div {
  padding: 1em 1em;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  gap: 1em;
  justify-content: left;
  align-items: center;
}

.nav-link li.active {
  background-color: rgba(68, 193, 66, 0.24);
}

.nav-link li.active a {
  color: #fff;
}

.nav-link li.active div {
  color: #fff;
}


.nav-link li.sub-li a {
  padding: .5em 0;
  font-size: 1rem;
  color: #fff;
  display: flex;
  gap: 1em;
  justify-content: left;
  align-items: center;
  padding-left: 1em;
  margin: 0 1em;
}

.nav-link li.sub-li a:hover {
  color: #333;
  background-color: #fff;
}

.nav-link li:not(.sub-li) a:hover {
  color: #fff;
}

.logos-nav {
  display: grid;
  grid-template-areas: "navmenu navplatforms exchanges infouser";
  grid-template-columns: 210px 29em 1fr auto;
}

.info-user {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin-right: 2em;
}