.page-item.active .page-link {
  background-color: var(--color-as) !important;
  color: white !important;
}

.page-link{
  color: var(--color-as) !important;
}



body.dark-theme .page-item .page-link {
  color: white !important;
  pointer-events: none;
  border: solid 1px var(--dark-theme-border) !important;
  background-color: var(--dark-theme-body);
}

body.dark-theme .page-item.active .page-link {
  color: white !important;
  pointer-events: none;
  border: solid 1px var(--dark-theme-border) !important;
  background-color: var(--dark-theme-border) !important;
}

body.dark-theme .page-item.disabled .page-link {
  color: grey !important;
  pointer-events: none;
  border: solid 1px var(--dark-theme-border) !important;
  background-color: var(--dark-theme-border) !important;
}
