:root {
  --dark-color: #1a1c1e;
  --dark-color-text: #b6b6b6;
  --background-color: #f8faff;
  --background-trasnparent: #ffffffaa;
  --background-color-gray: #eceaea;
  /* --color-as: #645df4; */
  /* --color-as: #7dc346; */
  --color-as: #1a3e23;
  --color-as-ho: #437d51;
  --color-as-50a: #7dc34680;
  /* --color-as-50a: #645df480; */
  --bs-table-striped-as: #645df30f;
  /* --color-as-secondary: #1b3976; */
  --color-as-secondary: #243c2a;
  --light-theme-article: #f8faff;
  --border: #e0e6f5;
  --primary-color-black: #000000;
  /* dark-themes */
  --dark-theme-text: #a4aebd;
  --dark-theme-dark: #14151c;
  --dark-theme-body: #1d1f29;
  --dark-theme-article: #212431;
  --dark-theme-border: #35384a;
  --dark-theme-light: #262937;
  --text-color: #122750;
}

.link-as {
  color: var(--color-as-ho);
  text-decoration: underline;
  cursor: pointer;
}
.link-as:hover {
  color: var(--color-as);
}

@font-face {
  font-family: 'Montserrat';
  src: url(../fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format('woff2');
  font-feature-settings: "smcp" on;
  font-weight: 300;
 }

 @font-face {
  font-family: 'Montserrat';
  src: url(../fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format('woff2');
  font-feature-settings: "smcp" on;
  font-weight: 500;
 }

 @font-face {
  font-family: 'Montserrat';
  src: url(../fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format('woff2');
  font-feature-settings: "smcp" on;
  font-weight: 600;
 }

 @font-face {
  font-family: 'Montserrat';
  src: url(../fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format('woff2');
  font-feature-settings: "smcp" on;
  font-weight: 800;
 }

/* Dark theme */
html.dark-theme {
  background: var(--dark-theme-body);
}

body.dark-theme {
  background: var(--dark-theme-body);
  color: var(--dark-theme-text);
}

body.dark-theme .article-template {
  background: var(--dark-theme-article);
  color: white;
}

body.dark-theme .nav-layout {
  background: var(--dark-theme-article);
}

body.dark-theme .nav-layout, body.dark-theme .nav-layout .menu-title {
  color: white;
}

body.dark-theme td {
  color: white;
}

body.dark-theme .text-dark {
  color: white !important;
}

body.dark-theme .header-template {
  background: var(--dark-theme-article);
  color: white;
}

body.dark-theme thead {
  background: var(--dark-theme-body);
}

body.dark-theme table {
  border: var(--dark-theme-body) solid 1px;
}

body.dark-theme th {
  color: white !important; 
  background-color: var(--dark-theme-body) !important;
}

body.dark-theme input.form-control,body.dark-theme select.form-select, body.dark-theme textarea.form-control{
  border-color: var(--dark-theme-border) !important;
  background-color: var(--dark-theme-article) !important;
  color: white;
}

body.dark-theme input.form-select:disabled, body.dark-theme .form-control:disabled{
  border-color: var(--dark-theme-body) !important;
  background: var(--dark-theme-body) !important;
  color: white;
}

* {
  font-size: 12px;
}

body.dark-theme .table-striped>tbody>tr:nth-of-type(odd)>* {
  color:white;
}
body.dark-theme .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: var(--dark-theme-border) !important;
}

body.dark-theme .alert-success {
  background-color: var(--bs-green) !important;
  color: white !important;
}

body.dark-theme .alert-warning {
  background-color: var(--bs-warning) !important;
  color: black !important;
}

body.dark-theme .alert-danger {
  background-color: var(--bs-danger) !important;
  color: white !important;
}

body.dark-theme .alert-info {
  background-color: var(--bs-info) !important;
  color: black !important;
}

body.dark-theme .alert-primary {
  background-color: var(--bs-primary) !important;
  color: white !important;
}
body.dark-theme .header-title {
  background-color: var(--dark-theme-dark);
  color: var(--dark-theme-text);
}

body.dark-theme .modal-content {
  background-color: var(--dark-theme-article);
}

body.dark-theme .modal-header, body.dark-theme .modal-footer{
  border-color: var(--dark-theme-border);
}

body.dark-theme button.btn-close{
  filter: invert(42%) sepia(93%) saturate(1352%);
}


body.dark-theme .alert-as {
  background-color: var(--color-as-50a);
  color: #111;
}

body.dark-theme .placeholder {
  background-color: var(--color-as);
}

.bg-as-secondary {
  background-color: var(--border);
}

/* Light theme */
body {
  background-color: var(--background-color) !important;
  color: var(--primary-color-black) !important;
  font-family: 'Montserrat' !important;
  }

.form-control:disabled {
  background: #e9ecef !important;
  color: #212529;
}

.color-as{
  color: var(--color-as);
}

.cursor-pointer {
  cursor:pointer;
}

* {
 background: transparent; 
}

/* input:not(.form-check-input){
  background: #ffffff88 !important;
} */

.form-check-input:checked {
  background-color: var(--color-as-ho) !important;
  border-color: var(--color-as-50a) !important;
}

.btn {
  border-radius: 50em !important;
}

.btn-as {
  /* background-color: var(--color-as) !important;
  color: #ffffffee !important; */
  background: var(--color-as) !important;
  color: white !important;
}

.btn-as:hover {
  background-color: var(--color-as-ho) !important;
}

.btn-as-out {
  border-color: var(--primary-color-black) !important;
  color: var(--primary-color-black) !important;
}

.btn-as-out:hover {
  border-color: var(--color-as-secondary) !important;
  color: var(--color-as-secondary) !important;
}

.dark-theme .btn-as {
  background-color: var(--color-as) !important;
  color: #000000ee !important;
}

.dark-theme .btn-as:hover {
  background-color: var(--color-as-secondary) !important;
}

.dark-theme .btn-as-out {
  border-color: var(--color-as) !important;
  color: var(--color-as) !important;
}



th {
  color: var(--color-as) !important;
}

td, th{
  vertical-align: middle;
  text-align: center !important;
}

tr.border-bottom {
  border-bottom: solid 2px var(--color-as) !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: var(--bs-table-striped-as) !important;
}

.alert-as{
  background: var(--color-as);
  color: white;
}

.bg-as {
  background-color: var(--color-as) !important;
  color: white;
}

.bg-as-seccondary{
  background-color: var(--color-as-secondary) !important;
}

.td-price{
  text-align: right !important;
}

.td-date {
  text-align: center !important;
}

body.dark-theme .tree-child{
  background-color: var(--dark-theme-body);
  border-color: var(--dark-theme-border) !important;
  margin: -2px;
  z-index: 10;
}

body.dark-theme .tree-child:not(.root):after{
  content: "→";
  font-size: 2em;
  position: absolute;
  top: 1em;
  left: -0.9em;
  color: var(--color-as);
}


body.dark-theme .tree-container::before{
  content: "";
  font-size: 2em;
  position: absolute;
  top: 3.5em;
  left: -2px;
  height: calc(100% - 5.2em);
  width: 2px;
  background-color: var(--color-as);
  z-index: 0;
}

body.dark-theme .tree-container{
  position: relative;
  border-left: solid 0px var(--color-as) !important;
}

.logo-img {
  background-image: url('../images//logo-dark.png');
  width: 100%;
  height: 5em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

body.dark-theme .logo-img {
  background-image: url('../images//logo-light.png');
}

.animaton-rotate-ing {
  animation:spin 1s linear infinite;
}

.dark-theme .form-control {
  border-color: var(--dark-theme-border) !important;
  background-color: var(--dark-theme-article) !important;
  color: white;
}

@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

body.dark-theme .container-in-article {
  padding: .5em;
  background: var(--dark-theme-body);
  color: var(--dark-theme-text);
}

body.dark-theme select.form-control:focus {
  color: white !important;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-as); 
  border-radius: 10px;
}

@media screen and (min-width: 960px) {
  .d-wrap {
    display: grid;
    grid-template-columns: calc(100vw - 21em) minmax(0, 1fr);
    overflow-x: scroll;
    overflow-y:hidden !important;
  }
}

@media screen and (max-width: 959px) {
  .d-wrap {
    display: grid;
    grid-template-columns: calc(100vw - 5em) minmax(0, 1fr);
    overflow-x: scroll;
    scrollbar-color: red yellow !important;
    overflow-y:hidden !important;
  }
}
