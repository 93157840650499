.accordion-button {
  transition: transform 0.4s ease-in-out;
}

.accordion-header h2 {
  color: lightgreen; /* Cambiar el color del texto a un verde claro */
}

.accordion-collapse {
  transition: max-height 0.4s ease-in-out;
}

.bgColor {
  background-color: black !important ;
}

.faqPregunta {
  margin: 0;
  color: #fff;
}

.faqRespuesta {
  color: #100f0f;
}
