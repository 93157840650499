.header-template {
  background-color: var(--light-theme-article);
  padding: 1em .5em;
  margin: 1em;
  border-radius: .3em;
}

.table-responsive {
  /* border-radius: 15px; */
  background-color: var(--background-colo);
}

.table thead th {
  padding: 20px;
  background-color: black;
  color: white !important; /* Para que el texto sea visible */
}

.table thead th:first-child {
  border-top-left-radius: 10px;
}

.table thead th:last-child {
  border-top-right-radius: 10px;
}


.article-template {
  background-color: var(--light-theme-article);
  padding: 0;
  border-radius: .3em;
}

.tarjetaInicio {
  box-shadow: 2px 8px 20px rgba(0, 0, 0, 0.2);
  border-radius: .5em;
}

.article-tarjetaGenerica {
  align-items: left;
  border-radius: .5em;
  /* background: var(--background-color-gray); */
}

.titulo-tarjeta-div {
  font-weight: 500;
  font-size: 10px;
  display: flex;
  align-items: center;
}

.numero-tarjeta-div {
  font-weight: 500;
  font-size: 12px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}
/* style de dashboard */
.container-first {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 10px;
}

.grid-grafico-caluladora {
  display: grid;
  gap: 1em;
  align-content: stretch;
  align-items: start;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-areas:
    "grafico tarjetas calculadora";
}

.tarjetas-transferencias-retiros {
  display: grid;
  gap: 20px;
  align-content:stretch;
  height: 100%;
  grid-template-areas:
  "transferencias"
  "retiros";
}

@media (max-width: 1400px) and (min-width: 768.1px) {
  .grid-grafico-caluladora {
    grid-template-areas:
    "grafico grafico tarjetas"
    "calculadora calculadora calculadora";
  }
}

@media (max-width: 768px) {
  .grid-grafico-caluladora {
    grid-template-areas:
      "grafico"
      "tarjetas"
      "calculadora";
  }

  .tarjetas-transferencias-retiros {
    grid-template-columns: 1fr; 
  }

  .container-first {
    grid-template-columns: 1fr; 
  }

  .tarjeta-margin {
    margin-bottom: 10px
  }
}

/* calculadora */
.gridTipoCambio {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 0;
}
